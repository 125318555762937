import React from 'react'
import { Section } from '../components/shared/section'
import { H2, LargeParagraph } from '../components/shared/typography'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { Link } from 'gatsby'

const NotFoundPage = () => (
  <Layout>
    <Section fullpage>
      <SEO title="404: Not found" />
      <H2><br/><br/>404</H2>
      <LargeParagraph>Oops, maybe try our <Link to="/work">work</Link> page.</LargeParagraph>
    </Section>
  </Layout>
)

export default NotFoundPage
